import { Route, Routes } from "react-router-dom";
import HomeLayout from '../Layouts/HomeLayout';
import Home02 from "../Pages/Homes/Home02";
import Services01 from "../Pages/Services/Services01";
import Services02 from "../Pages/Services/Services02";
import ContactPage from "../Pages/ContactPage";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";

export const AppRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<HomeLayout/> }>
                    <Route path="/" element={<Home02/>}/>
                    {/* <Route path="/project" element={<Projects01/>}/>
                    <Route path="/project-2" element={<Projects02/>}/>
                    <Route path="/project-details" element={<ProjectDetails/>}/>
                    
                    <Route path="/service-details" element={<ServiceDetails/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/team" element={<TeamPage/>}/>
                    <Route path="/blog" element={<Blog01/>}/>
                    <Route path="/blog-2" element={<Blog02/>}/>
                    <Route path="/blog-details" element={<BlogDetails/>}/> */}
                    <Route path="/services" element={<Services01/>}/>
                    <Route path="/services-2" element={<Services02/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    {/* <Route path="/faq" element={<FAQ/>}/> */}
                    <Route path="*" element={<ErrorPage/>}/>
                </Route> 
            </Routes>
        </>
    );
    }