import React from 'react';
import ServiceCard02_01 from '../../Components/ServiceCards/ServiceCard02_01';
import PaginationDiv from '../../Components/TeamElements/PaginationDiv';

const ServiceContainer01 = () => {
    const ServiceData01 = [
        {
            id: '1',
            img: 'images/services/s16.png',
            // img02: 'images/team/fandj/5.jpeg',
            img02: 'images/services/Service_Hover_images/1.jpg',
            tag: 'CARPENTRY',
            title: 'F and J All Renovations is the safe, reliable and cost effective in-house remodeling company.'
        },
        {
            id: '2',
            img: 'images/services/s15.png',
            img02: 'images/services/Service_Hover_images/2.jpg',
            tag: 'FLOORS',
            title: 'F and J All Renovations is the safe, reliable and cost effective in-house remodeling company.'
        },
        {
            id: '3',
            img: 'images/services/s14.png',
            img02: 'images/services/Service_Hover_images/3.jpg', 
            tag: 'PAINTING',
            title: 'F and J All Renovations is the safe, reliable and cost effective in-house remodeling company.'
        },
    ];

    return (
        <div className="main_wrapper">
            <div className="section service services_inner_page">
                <div className="container">
                    <div className="row">
                        {
                            ServiceData01.map(data =>
                                <ServiceCard02_01
                                    key={data.id}
                                    data={data}
                                />
                            )
                        }

                        {/* <PaginationDiv /> */}

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceContainer01;