import { GoogleMap, LoadScript } from '@react-google-maps/api';
import React from 'react';


const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: 40.6940925,
    lng: -73.6579273
};

const onLoad = marker => {
    console.log('marker: ', marker);
    
    
};

const Map = () => {
    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                // Add a marker to the map once we have the address
                onLoad={onLoad}
                // style={{ height: "100vh" }}
                // add address 613 ADAMS AVE WEST HEMPSTEAD NY 11552\
            >
                { /* Child components, such as markers, info windows, etc. */}
                <></>
            </GoogleMap>
        </LoadScript>
    );
};

export default Map;