import React from 'react';

const LatestNews = () => {
    return (
        <div className="col-lg-4 col-md-12">
            <div className="section_header text-left">
                <h6 className="section_sub_title">Latest News</h6>
                <h1 className="section_title">Our Latest News</h1>
                <p className="section_desc">F and J All Renovations is the best construction company. It has best team who are provideing best service possible.</p>
            </div>
            <div className="read_more read_all">
                <a className="button" href="blog-1">Learn More</a>
            </div>
        </div>
    );
};

export default LatestNews;